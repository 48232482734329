import React, { useEffect, useState } from "react";
import {
  CountrySelect,
  PredictionJson,
  match,
  matchPrediction,
} from "../Types/Types";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  LinearProgress,
  Toolbar,
  Typography,
} from "@mui/material";
import ScorePicker from "./ScorePicker";
import PeakDrawer from "./PeakDrawer";
import { useNavigate, useParams } from "react-router-dom";
import EndScoreComponent from "./EndScoreComponent";
import LogoutIcon from "@mui/icons-material/Logout";
import {
  GetCurrentUserFromLocalStorage,
  logout,
} from "../Services/LoginService";
import MJAppBar from "./MJAppBar";

interface PickSliderProps {
  matches: match[];
}

const generatePredictions = (matches: match[]): matchPrediction[] => {
    return matches.map((match) => {
    return {
      match: match,
      userCode: "0000",
      teamAScore: 0,
      teamBScore: 0,
    };
  });
};

const PickSlider: React.FC<PickSliderProps> = ({ matches }) => {
  const [index, setIndex] = useState(0);
  const [mode, setMode] = useState<"matches" | "top4">("matches");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openSendingDialog, setOpenSendingDialog] = useState(false);
  const [currentMatch, setCurrentMatch] = useState<match>(matches[0]);
  const [topFour, setTopFour] = useState<CountrySelect | undefined>();
  const [predictions, setPredictions] = useState<matchPrediction[]>(
    generatePredictions(matches)
  );
  const [currentPrediction, setCurrentPrediction] = useState<matchPrediction>(predictions[0]);
  const matchPrediction: matchPrediction = predictions[index];

  const roundNumber = process.env.REACT_APP_ROUND_NUMBER;

  const navigate = useNavigate();

  const handlePrevious = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  useEffect(() => {
    setCurrentMatch(matches[index]);
    setCurrentPrediction(predictions[index]);
  }, [index]);

  const handleNext = () => {
    if (index < matches.length) {
      setIndex(index + 1);
    }
  };

  const handlePostPredictions = () => {
    setOpenSendingDialog(true);

    setTimeout(() => {
      fetch("https://magical-northcutt.81-173-112-48.plesk.page/api/predict", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          predictions: predictions,
          top4: topFour,
          userId: GetCurrentUserFromLocalStorage()?.id,
          date: new Date().toISOString(),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            navigate("/success");
          }
        })
        .catch((error) => {
          alert(
            "Oeps! Er is iets misgegaan, probeer het opnieuw " + error.message
          );
        });

      setOpenSendingDialog(false);
    }, 3000);
  };

  const handleScoreChange = (pred: matchPrediction) => {
    predictions[index] = pred;
    setPredictions(predictions);
  };

  const backToMatchesBtn = (
    <Button
      disabled={index === 0}
      onClick={() => setMode("matches")}
      variant="contained"
      color="primary"
      fullWidth
    >
      Terug
    </Button>
  );

  const backBtn = (
    <Button
      disabled={index === 0}
      onClick={handlePrevious}
      variant="contained"
      color="primary"
      fullWidth
    >
      Vorige
    </Button>
  );

  const chooseTop4Btn = (
    <Button
      onClick={() => setMode("top4")}
      variant="contained"
      color="success"
      fullWidth
    >
      Top 4 kiezen
    </Button>
  );

  const next2Btn = (
    <Button onClick={handleNext} variant="contained" color="primary" fullWidth>
      Volgende
    </Button>
  );

  const sendPredictionsBtn = (
    <Button
      onClick={handlePostPredictions}
      variant="contained"
      color="warning"
      disabled={
        // !(
        //   topFour !== undefined &&
        //   topFour.country1 !== undefined &&
        //   topFour?.country2 !== undefined &&
        //   topFour?.country3 !== undefined &&
        //   topFour?.country4 !== undefined
        // )
        false
      }
      fullWidth
    >
      inleveren
    </Button>
  );

  const sendingResultsDialog = (
    <Dialog open={openSendingDialog} sx={{ textAlign: "center" }}>
      <DialogTitle color="primary" fontWeight="bold">
        Voorspellingen versturen
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Je voorspellingen worden verstuurd naar mj-toto server 🤖
        </DialogContentText>

        <Box
          sx={{ mx: "auto", my: 2, display: "flex", justifyContent: "center" }}
        >
          <img
            src={require("./ball.png")}
            alt=""
            style={{
              animation: "spin 1s linear infinite",
              height: "100px",
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );

  const currentUser = GetCurrentUserFromLocalStorage();
  return (
    <>
      {sendingResultsDialog}
      <PeakDrawer
        match={currentMatch}
        open={openDrawer}
        onDrawerClose={() => setOpenDrawer(false)}
      />
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <MJAppBar />
        <Box sx={{ width: "100%", textAlign: "center", flex: "1 0 auto" }}>
          {mode === "matches" ? (
            <>
              <LinearProgress
                variant="determinate"
                value={(index / (matches.length - 1)) * 100}
                sx={{ height: 20 }}
              />
              <div key={index}>
                <Typography
                  variant="h5"
                  align="center"
                  style={{ fontWeight: "bolder" }}
                >
                  {currentMatch.teamA.name} vs {currentMatch.teamB.name}
                </Typography>
                <div>
                  <Typography variant="h6" align="center">
                    Wedstrijd {index + 1} van {matches.length}
                  </Typography>
                </div>
              </div>
              <Button
                onClick={() => setOpenDrawer(!openDrawer)}
                variant="contained"
              >
                Ranglijst spieken 👀
              </Button>
              <ScorePicker
                matchPrediction={currentPrediction}
                onScoreChange={handleScoreChange}
              />
            </>
          ) : (
            <EndScoreComponent
              onTopFourChange={(t4) => {
                setTopFour(t4);
              }}
            />
          )}
        </Box>
        <Box style={{ alignItems: "center" }} sx={{ mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box mx={1}>{mode === "top4" ? backToMatchesBtn : backBtn}</Box>
            </Grid>
            <Grid item xs={6}>
              <Box mx={1}>
                {index === matches.length - 1
                  ? mode === "matches"
                    ? ( roundNumber === '1' ? chooseTop4Btn : sendPredictionsBtn)
                    : null
                  : mode === "matches"
                  ? next2Btn
                  : null}

                {mode === "top4" && sendPredictionsBtn}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default PickSlider;
