import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NotFoundComponent: React.FC = () => {

    const navigate = useNavigate();

    return (
        <Box display='flex' justifyContent='center' alignItems='center' textAlign='center'>
            <Box>
                <Box display='flex' justifyContent='center' fontSize={'30px'}>
                    <h1>4</h1><h1 style={{ animation: 'spin 1s linear infinite' }}>⚽</h1><h1>4</h1>
                </Box>
                <h1>Pagina niet gevonden!</h1>
                <Box display='flex' justifyContent='center'>
                    <h1>🤷‍♀️🤷‍♂️</h1>
                </Box>

                <Box mt={3}>
                    <Button size='large' variant="contained" color="primary" onClick={() => navigate('/')}>Terug naar home</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default NotFoundComponent;