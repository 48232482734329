import jsPDF from "jspdf";
import { GetCurrentUserFromLocalStorage, GetUserById } from "../../Services/LoginService";
import { PredictionJson, PredictionJsonEmpty } from "../../Types/Types";

const ROUND_NUMBER = process.env.REACT_APP_ROUND_NUMBER;

export const handleDownloadDataJSON = (row: PredictionJson) => {
  const jsonData = JSON.stringify(row);
  const blob = new Blob([jsonData], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `data_${GetUserById(row.userId)?.name}.json`;
  link.click();
};

export const handleDownloadAllDataJSON = (
  allPredictions?: PredictionJsonEmpty[]
) => {
  if (!allPredictions) return;
  const jsonData = JSON.stringify(allPredictions);
  const blob = new Blob([jsonData], { type: "application/json" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "alle_data.json";
  link.click();
};

export const handleDownloadAllAsCSV = (allPredictions: PredictionJson[]) => {
  const csv = allPredictions?.map((row) => {
    return `${GetUserById(row.userId)?.name},${row.predictions
      .map((pred) => `${pred.teamAScore}-${pred.teamBScore}`)
      .join(",")}`;
  });

  const csvString = csv?.join("\n");
  if (!csvString) return;
  const blob = new Blob([csvString], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = "alle_data.csv";
  link.click();
};

const ADMINS = process.env.REACT_APP_ADMINS
? JSON.parse(process.env.REACT_APP_ADMINS)
: [];

export const checkIfIsAdmin = (): boolean => {
    if (!ADMINS) return false;
    const currentuser = GetCurrentUserFromLocalStorage();
    if (currentuser && ADMINS.includes(currentuser.id)) {
      return(true);
    } else {
      return(false);
    }
};

export const handleDownloadDataCSV = (row: PredictionJsonEmpty) => {
  let csv = `deelnemer;${GetUserById(row.userId)?.name}\ndeelnemer_id;${row.userId}\nronde;${ROUND_NUMBER}\ndatum;${new Date(row.date ?? 0).toLocaleString("en-GB")}\nwedstrijd;;;voorspelling;;
${row.predictions
  .map(
    (pred) =>
      `${pred.match.teamA.name};-;${pred.match.teamB.name};${pred.teamAScore};-;${pred.teamBScore}`
  )
  .join("\n")}`;

  csv += "\n";

  csv += `top4\n`;
  csv += `1e;${row.top4?.country1?.name}\n`;
  csv += `2e;${row.top4?.country2?.name}\n`;
  csv += `3e;${row.top4?.country3?.name}\n`;
  csv += `4e;${row.top4?.country4?.name}\n`;

  const blob = new Blob([csv], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = `data_${
    GetUserById(row.userId)?.name
  }_ronde${ROUND_NUMBER}.csv`;
  link.click();
};

export const handleDownloadAllDataPdf = (allPredictions?: PredictionJsonEmpty[]) => {
  if (!allPredictions) return;

  const doc = new jsPDF();
  const header = ['Gebruiker', 'Wedstrijd', 'Voorspelling'];
  const config = {
    autoSize: false,
    printHeaders: true
  };

  allPredictions.forEach((row) => {
    const playerName = GetUserById(row.userId)?.name;
    if (playerName) {
      const data: any[] = [];

      row.predictions.forEach((pred) => {
        const match = `${pred.match.teamA.name} vs ${pred.match.teamB.name}`;
        const prediction = `${pred.teamAScore}-${pred.teamBScore}`;
        data.push({ Gebruiker: playerName, Wedstrijd: match, Voorspelling: prediction});
      });

      doc.text(`${playerName} - ronde ${ROUND_NUMBER} - ${new Date(row.date ?? 0).toLocaleString("en-GB")} `, 10, 10);
      doc.table(10, 20, data, header, config);
      if (row === allPredictions[allPredictions.length - 1]) {
        // Last player, no new page
      } else {
        doc.addPage();
      }
    }
  });

  doc.save(`alle_data_ronde${ROUND_NUMBER}.pdf`);
}

export const handleDownloadDataPdf = (row: PredictionJsonEmpty) => {
  const doc = new jsPDF();
  const header = ['Wedstrijd', 'Voorspelling'];
  const data: any[] = [];

  row.predictions.forEach((pred) => {
    const match = `${pred.match.teamA.name} vs ${pred.match.teamB.name}`;
    const prediction = `${pred.teamAScore}-${pred.teamBScore}`;
    data.push({ Wedstrijd: match, Voorspelling: prediction });
  });

  const config = {
    autoSize: false,
    printHeaders: true
  };

  doc.text(
    `${GetUserById(row.userId)?.name} ${new Date(row.date ?? 0).toLocaleString("en-GB")} ronde ${ROUND_NUMBER}`, 10, 10);

  doc.table(10, 10, data, header, config);

  if (row.top4) {
    const top4Header = ['Positie', 'Land'];
    const top4Data = [
      { Positie: '1st', Land: row.top4.country1?.name },
      { Positie: '2nd', Land: row.top4.country2?.name },
      { Positie: '3rd', Land: row.top4.country3?.name },
      { Positie: '4th', Land: row.top4.country4?.name },
    ];
    doc.addPage();
    doc.text('Top 4', 10, 10);
    doc.table(10, 20, top4Data, top4Header, config);
  }
  doc.save(`data_${GetUserById(row.userId)?.name}_ronde${ROUND_NUMBER}.pdf`);
}
