import React from "react";
import {
  GetCurrentUserFromLocalStorage,
  logout,
} from "../Services/LoginService";
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MJAppBar from "./MJAppBar";
import { goToMJTotoSite } from "../MJT-utils";

const Success: React.FC = () => {
  const currentUser = GetCurrentUserFromLocalStorage();
  const navigate = useNavigate();

  return (
    <>
       <MJAppBar />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Box sx={{mx:3}}>
          <h2>Goed gedaan {currentUser?.name} !</h2>
          <h3 style={{ color: "gray" }}>
            Bedankt voor het invullen van je voorspellingen. Hou de mj-toto
            website in de gaten voor updates en veel plezier met het EK 2024!
          </h3>
          <img
            src="https://media1.giphy.com/media/v1.Y2lkPTc5MGI3NjExc2p4c2I1N3ljc3RiamQxMWtid2RkazZoOWZwODQ5anN5a2I5aXRpeiZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/RFuaVGibZONUDrnRIY/giphy.gif"
            width="60%"
            alt="score!"
          />
          <p></p>
          <Button
            sx={{ my: 2 }}
            size="large"
            onClick={() => goToMJTotoSite()}
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
          >
            Naar mj toto site
          </Button>

          <Typography variant="h6" sx={{fontWeight: 'bold', color: 'gray'}}>of</Typography>

          <Button
            sx={{ my: 2 }}
            size="large"
            onClick={() => navigate("/downloads")}
            variant="contained"
            color="warning"
            endIcon={<ArrowForwardIcon />}
          >
            Mijn voorspellingen
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Success;
