import { Box, Button, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ManualLogin: React.FC = () => {
  const [code, setCode] = useState<string | undefined>(undefined);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (isNumeric(value) === true && value.length <= 4 && value.length >= 0) {
      setCode(value);
    }
  };

  const isNumeric = (value: string): boolean => {
    return !isNaN(Number(value));
  };

  const navigate = useNavigate();

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      textAlign="center"
    >
      <Box>
        <img
          src={require("./logo_rectangular.png")}
          alt="mj-toto"
          style={{ width: "200px" }}
        />
        <h1>Code invullen</h1>
        <h3 style={{ color: "gray" }}>
          Je code bestaat uit 4 nummers, bijv. "1234"
        </h3>
        <TextField
          label="4 cijfer code"
          id="outlined-start-adornment"
          value={code === undefined || code.toString().length === 0 ? "" : code}
          sx={{ m: 1, width: "25ch" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">code:</InputAdornment>
            ),
          }}
          onChange={handleUsernameChange}
          onKeyDown={(event) => {
            if (event.key === "Enter" && code?.length === 4) {
              navigate(`/login/${code}`);
            }
          }}
        />
        <Button
          onClick={() => navigate(`/login/${code}`)}
          style={{ width: "60%" }}
          disabled={code?.length !== 4}
          variant="contained"
          color="primary"
        >
          Inloggen
        </Button>
      </Box>
    </Box>
  );
};

export default ManualLogin;
