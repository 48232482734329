import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  GetCurrentUserFromLocalStorage,
  logout,
} from "../Services/LoginService";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Props {
  // Define your component's props here
}

const MJAppBar: React.FC<Props> = (props) => {
  const [openConfimLogoutDialog, setOpenConfimLogoutDialog] = useState(false);

  const currentUser = GetCurrentUserFromLocalStorage();
  const navigate = useNavigate();

  const logoutDialog = (
    <Dialog sx={{ textAlign: "center" }} open={openConfimLogoutDialog}>
      <DialogTitle color="primary" sx={{ fontWeight: "bold" }}>
        Uitloggen
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Weet je zeker dat je wilt uitloggen? Je kunt altijd weer inloggen met
          je code.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            logout();
            setOpenConfimLogoutDialog(false);
            navigate("/login");
          }}
        >
          Ja
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => setOpenConfimLogoutDialog(false)}
        >
          Nee
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {logoutDialog}
      {currentUser !== undefined ? (
        <AppBar position="static" style={{ boxShadow: "none" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => navigate(-1)}
              sx={{mr:1}}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" fontWeight="bolder">
              {currentUser.name}
            </Typography>
            <Button
              sx={{ ml: "auto", color: "white", borderColor: "white" }}
              onClick={() => setOpenConfimLogoutDialog(true)}
              variant="outlined"
              endIcon={<LogoutIcon />}
            >
              Uitl⚽️ggen
            </Button>
          </Toolbar>
        </AppBar>
      ) : null}
    </>
  );
};

export default MJAppBar;
