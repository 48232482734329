import React, { useEffect, useState } from "react";
import { TextField, Button, Grid, Box, Typography } from "@mui/material";
import { match, matchPrediction } from "../Types/Types";
import { GetCurrentUserFromLocalStorage } from "../Services/LoginService";
import EndScoreComponent from "./EndScoreComponent";

interface ScorePickerProps {
  matchPrediction: matchPrediction;
  onScoreChange: (prediction: matchPrediction) => void;
}

const ScorePicker: React.FC<ScorePickerProps> = (props) => {
  const [teamAScore, setTeamAScore] = useState(0);
  const [teamBScore, setTeamBScore] = useState(0);

  useEffect(() => {
    if (teamAScore === 10 || teamBScore === 10) {
      alert("Weet je het zeker? Dat zijn wel veel doelpunten!");
    }

    props.onScoreChange({
      ...props.matchPrediction,
      teamAScore: teamAScore,
      teamBScore: teamBScore,
    });

    console.log({
      ...props.matchPrediction,
      teamAScore: teamAScore,
      teamBScore: teamBScore,
    });
  }, [teamAScore, teamBScore]);

  useEffect(() => {
    setTeamAScore(props.matchPrediction.teamAScore);
    setTeamBScore(props.matchPrediction.teamBScore);
  }, [props.matchPrediction]);

  return (
    <Grid container spacing={3} style={{ marginTop: 100, marginBottom: 50 }}>
      <Grid item xs={6}>
        <Box mx={2}>
          <img
            src={`https://flagcdn.com/${props.matchPrediction.match.teamA.code.toLowerCase()}.svg`}
            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" }}
            alt={props.matchPrediction.match.teamA.name}
            height={"60"}
          />
          <Typography variant="h6" fontWeight="bold">
            {props.matchPrediction.match.teamA.name}
          </Typography>
          <Box my={3}>
            <Typography variant="h3">{teamAScore}</Typography>
            <Box my={2}>
              <Grid container spacing={2} style={{ alignItems: "center" }}>
                <Grid item xs={6}>
                  <Box mx={"auto"}>
                    <Button
                      disabled={teamAScore === 0}
                      size="large"
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => setTeamAScore(teamAScore - 1)}
                    >
                      ➖
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mx={"auto"}>
                    <Button
                      size="large"
                      variant="contained"
                      color="success"
                      fullWidth
                      onClick={() => setTeamAScore(teamAScore + 1)}
                    >
                      ➕
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box mx={2}>
          <img
            src={`https://flagcdn.com/${props.matchPrediction.match.teamB.code.toLowerCase()}.svg`}
            height={"60"}
            style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" }}
            alt={props.matchPrediction.match.teamB.name}
          />
          <Typography variant="h6" fontWeight="bold">
            {props.matchPrediction.match.teamB.name}
          </Typography>
          <Box my={3} mx="auto">
            <Typography variant="h3">{teamBScore}</Typography>
            <Box my={2}>
              <Grid container spacing={2} style={{ alignItems: "center" }}>
                <Grid item xs={6}>
                  <Box mx={"auto"}>
                    <Button
                      disabled={teamBScore === 0}
                      size="large"
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => setTeamBScore(teamBScore - 1)}
                    >
                      ➖
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box mx={"auto"}>
                    <Button
                      size="large"
                      variant="contained"
                      color="success"
                      fullWidth
                      onClick={() => setTeamBScore(teamBScore + 1)}
                    >
                      ➕
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScorePicker;
