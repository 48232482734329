import PickSlider from "./Components/PickSlider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Welcome from "./Components/Welcome";
import LoginComponent from "./Components/Login";
import NotFoundComponent from "./Components/NotFoundComponent";
import ManualLogin from "./Components/ManualLogin";
import { ronde1, ronde2, ronde3, ronde4, ronde5 } from "./matches";
import Admin from "./Components/Admin/Admin";
import Success from "./Components/Success";
import Downloads from "./Components/Downloads";

function App() {

  return (
    <div
      className="App"
      style={{
        maxWidth: "600px",
        marginLeft: "auto",
        marginRight: "auto",
        height: "100vh",
        backgroundColor: "white",
      }}
    >
      <>
        <BrowserRouter>
          <Routes>
            <Route path="/login/:id" element={<LoginComponent />} />
            <Route path="/login" element={<ManualLogin />} />
            <Route path="/" element={<Welcome />} />
            <Route path="/kies" element={<PickSlider matches={ronde5} />} />
            <Route path="/admin" element={<Admin />} />\
            <Route path="/success" element={<Success />} />
           <Route path="/downloads" element={<Downloads />} />
            <Route path="*" element={<NotFoundComponent />} />
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
