import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../Types/Types";
import { GetCurrentUserFromLocalStorage } from "../Services/LoginService";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MJAppBar from "./MJAppBar";
import { checkIfIsAdmin } from "./Admin/Admin.utils";
import ReplayIcon from "@mui/icons-material/Replay";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const Welcome: React.FC = () => {
  const [name, setName] = React.useState("");
  const [countdown, setCountdown] = React.useState<number | undefined>(
    undefined
  );
  const [allPredictions, setAllPredictions] = React.useState<any | undefined>(
    undefined
  );
  const [hasAlreadySubmitted, setHasAlreadySubmitted] = React.useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    if (allPredictions !== undefined) {
      const hasSubmitted = allPredictions.length > 0;
      setHasAlreadySubmitted(hasSubmitted);
    }
  }, [allPredictions]);

  useEffect(() => {
    const currentUser: User | undefined = GetCurrentUserFromLocalStorage();
    if (currentUser === undefined) {
      navigate("/login");
    } else {
      setName(currentUser.name);
    }
  }, []);

  const navigate = useNavigate();

  const currentUser: User | undefined = GetCurrentUserFromLocalStorage();

  const fetchData = async () => {
    setTimeout(async () => {
      const response = await fetch(
        `https://magical-northcutt.81-173-112-48.plesk.page/api/getAllPredictions/${currentUser?.id}`
      );
      const data = await response.json();

      console.warn(data);
      setAllPredictions(data);
    }, 1000);
  };

  if (allPredictions === undefined) {
    setTimeout(() => {
      fetchData();
    }, 200);
  }

  useEffect(() => {
    console.warn(process.env.REACT_APP_ROUND_END_DATE);
    const targetTimestamp = new Date(
      process.env.REACT_APP_ROUND_END_DATE?.toString() ?? 0
    ).getTime();
    if (targetTimestamp === 0) {
      setCountdown(0);
      return;
    }
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = targetTimestamp - now;

      if (distance <= 0) {
        clearInterval(interval);
        setCountdown(0);
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdown(
          days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds
        );
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const spinningBall = (
    <Box style={{ animation: "spin 1s linear infinite" }}>
      <img src={require("./ball.png")} alt="" style={{ height: "100px" }} />
    </Box>
  );

  const formatTime = (time: number): string => {
    const days = Math.floor(time / (24 * 3600));
    const hours = Math.floor((time % (24 * 3600)) / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    const daysString = days === 1 ? "dag" : "dagen";

    const daysCompleteString = days > 0 ? `${days} ${daysString}` : "";

    return `${daysCompleteString} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const countDownBox = (
    <Box sx={{ backgroundColor: "lightgray", p: 1, borderRadius: "10px" }}>
      <Typography
        variant="h5"
        style={{ color: countdown === 0 ? "red" : "white" }}
      >
        {countdown === 0
          ? "De deadline is verstreken!"
          : "De deadline is over:"}
      </Typography>

      {countdown !== 0 && (
        <Typography variant="h5" style={{ color: "green", fontWeight: "bold" }}>
          {formatTime(countdown ?? 0)}
        </Typography>
      )}
    </Box>
  );

  const normalWelcome = (
    <Box sx={{ mx: 3 }}>
      {countDownBox}
      {countdown !== 0 && (
        <>
          <Typography variant="h5" style={{ color: "gray" }}>
            Welkom bij de mj-toto van EK 2024. Klik op doorgaan om je
            voorspellingen in te vullen.
          </Typography>
          {spinningBall}
        </>
      )}
    </Box>
  );

  const alreadySubmitted = (
    <Box sx={{ mx: 1 }}>
      <Alert severity="warning">
        <AlertTitle sx={{ fontWeight: "bold" }}>Let op!</AlertTitle>
        Je hebt al een voorspelling gemaakt voor deze ronde. Als je wilt, kun je
        deze opnieuw invullen.
      </Alert>

      {countdown !== 0 && <></>}
      {countDownBox}
    </Box>
  );

  return (
    <>
      <MJAppBar />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Box sx={{ mx: 3 }}>
          <img
            src={require("./logo.png")}
            alt="mj-toto"
            style={{ width: "200px" }}
          />
          <h2>Welkom {name} !</h2>

          {hasAlreadySubmitted === undefined && spinningBall}
          {hasAlreadySubmitted === true && alreadySubmitted}
          {hasAlreadySubmitted === false && normalWelcome}

          {hasAlreadySubmitted !== undefined && (
            <Grid container spacing={1} sx={{ mt: 2 }}>
              {hasAlreadySubmitted === true && (
                <Grid item xs={6}>
                  <Button
                    size="large"
                    onClick={() => navigate("/downloads")}
                    variant="contained"
                    color="warning"
                    fullWidth
                    sx={{ height: "100%" }}
                  >
                    Mijn voorspellingen
                  </Button>
                </Grid>
              )}
              {hasAlreadySubmitted === true && (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/kies")}
                    fullWidth
                    sx={{ height: "100%" }}
                  >
                    opnieuw invullen
                  </Button>
                </Grid>
              )}

              {!hasAlreadySubmitted && (
                <Grid item xs={!checkIfIsAdmin() ? 12 : 6}>
                  <Button
                    sx={{height: "100%", mx: "auto"}}
                    onClick={() => navigate("/kies")}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    Doorgaan!
                  </Button>
                </Grid>
              )}

              {checkIfIsAdmin() && (
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => navigate("/admin")} 
                    fullWidth
                    sx={{ height: "100%", mx: "auto"}}
                  >
                    Admin pagina
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Welcome;
