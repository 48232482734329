import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Alert,
  AppBar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CheckIfUserExists,
  GetCurrentUserFromLocalStorage,
  GetUserById,
  getAllUsers,
  logout,
} from "../../Services/LoginService";
import { PredictionJson, PredictionJsonEmpty, User } from "../../Types/Types";
import RefreshIcon from "@mui/icons-material/Refresh";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  handleDownloadAllDataJSON,
  handleDownloadAllDataPdf,
  handleDownloadDataCSV,
  handleDownloadDataPdf,
} from "./Admin.utils";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Admin() {
  const [open, setOpen] = useState(false);
  const [openResultsDialog, setOpenResultsDialog] = useState(false);
  const [openShowTop4, setOpenShowTop4] = useState(false);
  const [isAdmin, setIsAdmin] = useState<boolean | undefined>(undefined);
  const [selectedResult, setSelectedResult] = useState<
    PredictionJsonEmpty | undefined
  >(undefined);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);

  const [allPredictions, setAllPredictions] = useState<
    PredictionJsonEmpty[] | undefined
  >(undefined);

  const [allRoundsPredictions, setAllRoundsPredictions] = useState<
    PredictionJson[][] | undefined
  >(undefined);

  const ADMINS = process.env.REACT_APP_ADMINS
    ? JSON.parse(process.env.REACT_APP_ADMINS)
    : [];

  console.log(ADMINS);

  const ROUND_NUMBER = process.env.REACT_APP_ROUND_NUMBER;

  console.log(ROUND_NUMBER);

  const currentUser = GetCurrentUserFromLocalStorage();

  const navigate = useNavigate();

  const checkIfIsAdmin = () => {
    if (!ADMINS) return;
    const currentuser = GetCurrentUserFromLocalStorage();
    if (currentuser && ADMINS.includes(currentuser.id)) {
      setIsAdmin(true);
      setOpen(false);
    } else {
      setIsAdmin(false);
    }
  };

  if (isAdmin === undefined) {
    checkIfIsAdmin();
  }

  const fetchCurrentRoundData = async () => {
    setTimeout(async () => {
      const response = await fetch(
        "https://magical-northcutt.81-173-112-48.plesk.page/api/getPredictions"
      );
      const data: PredictionJson[] = await response.json();
      // data.pop();

      console.warn(data);

      const allUsers = await getAllUsers();

      if (allUsers) {
        const myPredictions: PredictionJsonEmpty[] = allUsers.map((user) => {
          const usersPrediction = data.find((x) => x.userId === user.id);

          if (!usersPrediction) {
            return {
              predictions: [],
              top4: undefined,
              userId: user.id,
              date: undefined,
              roundNumber: undefined,
            };
          }

          return usersPrediction;
        });

        setAllPredictions(myPredictions);
      }
    }, 1000);
  };

  const fetchAllRoundsData = async () => {
    setTimeout(async () => {
      const response = await fetch(
        "https://magical-northcutt.81-173-112-48.plesk.page/api/getAllPredictionsAllRounds"
      );
      let data: PredictionJson[][] = await response.json();
      // data.pop();

      console.warn(data);

      data = data.filter((x) => x !== null);

      const allUsers = await getAllUsers();

      setAllRoundsPredictions(data);

      // if (allUsers) {
      //   const myPredictions: PredictionJsonEmpty[][] = allUsers.map((user) => {
      //     const usersPrediction = data.find((x) => x.userId === user.id);

      //     if (!usersPrediction) {
      //       return {
      //         predictions: [],
      //         top4: undefined,
      //         userId: user.id,
      //         date: undefined,
      //         roundNumber: undefined,
      //       };
      //     }

      //     return usersPrediction;
      //   });

      // }
    }, 1000);
  };

  if (allPredictions === undefined) {
    setTimeout(() => {
      fetchCurrentRoundData();
    }, 200);
  }

  if (allRoundsPredictions === undefined) {
    setTimeout(() => {
      fetchAllRoundsData();
    }, 200);
  }

  const showAllPredictionsByRound = (
    <>
      {allRoundsPredictions &&
        allRoundsPredictions.map((round, index) => (
          <Accordion
            defaultExpanded={index === allRoundsPredictions?.length - 1}
            sx={{ mt: 2 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Ronde {index + 1}
            </AccordionSummary>
            <AccordionDetails>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Gebruiker+Datum
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Wedstrijden
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Top4</TableCell>
                  </TableRow>
                </TableHead>
                {round?.map((row) => (
                  <TableRow
                    key={row.userId}
                    sx={{
                      "&:nth-of-type(even)": {
                        border: 0,
                        backgroundColor: "lightblue",
                      },
                    }}
                  >
                    <TableCell>
                      {row.date && (
                        <>
                          <IconButton
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.primary.main,
                              color: "white",
                            }}
                            onClick={() => handleDownloadDataCSV(row)}
                          >
                            <DownloadIcon />
                          </IconButton>
                          <IconButton
                            sx={{
                              backgroundColor: (theme) =>
                                theme.palette.secondary.main,
                              color: "white",
                            }}
                            onClick={() => handleDownloadDataPdf(row)}
                          >
                            <DownloadIcon />
                          </IconButton>
                        </>
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <p style={{ fontWeight: "bold" }}>
                        {GetUserById(row.userId)?.name}
                      </p>
                      <p>
                        {row.date !== undefined
                          ? new Date(row.date).toLocaleString("en-GB")
                          : ""}
                      </p>
                    </TableCell>
                    <TableCell>
                      {row.date && (
                        <IconButton
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.primary.main,
                            color: "white",
                          }}
                          onClick={() => {
                            setSelectedResult(row);
                            setOpenResultsDialog(true);
                            setSelectedUser(GetUserById(row.userId));
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.date && row.top4 !== undefined && (
                        <IconButton
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.primary.main,
                            color: "white",
                          }}
                          onClick={() => {
                            setSelectedResult(row);
                            setOpenShowTop4(true);
                            setSelectedUser(GetUserById(row.userId));
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
    </>
  );

  const showPredictionDialog = (
    <Dialog
      keepMounted
      onClose={() => setOpenResultsDialog(false)}
      open={openResultsDialog}
    >
      <DialogTitle color="grey" fontWeight="bold">
        Voorspellingen van {selectedUser?.name}
        <IconButton
          sx={{ position: "absolute", right: "8px", top: "8px" }}
          onClick={() => setOpenResultsDialog(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Match</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Scores</TableCell>
              </TableRow>
            </TableHead>
            {selectedResult !== undefined ? (
              <TableBody>
                {selectedResult?.predictions.map((pred) => (
                  <TableRow
                    key={pred.match.teamA.code + pred.match.teamB.code}
                    sx={{
                      "&:nth-of-type(even)": {
                        border: 0,
                        backgroundColor: "lightblue",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {pred.match.teamA.name} VS {pred.match.teamB.name}
                    </TableCell>
                    <TableCell>
                      {pred.teamAScore} - {pred.teamBScore}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <CircularProgress />
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );

  const downloadButtons = (
    <>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<RefreshIcon />}
        onClick={() => setAllPredictions(undefined)}
        sx={{ mx: 1 }}
      >
        Refresh
      </Button>

      <Button
        variant="contained"
        color="success"
        onClick={() => handleDownloadAllDataJSON(allPredictions)}
        sx={{ mx: 1 }}
        endIcon={<DownloadIcon />}
      >
        Alle data (json)
      </Button>

      <Button
        variant="contained"
        color="success"
        onClick={() => handleDownloadAllDataPdf(allPredictions)}
        sx={{ mx: 1 }}
        endIcon={<DownloadIcon />}
      >
        Alle data (pdf)
      </Button>
    </>
  );

  const table = (
    <>
      <Button
        variant="outlined"
        color="primary"
        endIcon={<RefreshIcon />}
        onClick={() => setAllPredictions(undefined)}
        sx={{ mx: 1 }}
      >
        Refresh
      </Button>

      <Button
        variant="contained"
        color="success"
        onClick={() => handleDownloadAllDataJSON(allPredictions)}
        sx={{ mx: 1 }}
        endIcon={<DownloadIcon />}
      >
        Alle data (json)
      </Button>

      <Button
        variant="contained"
        color="success"
        onClick={() => handleDownloadAllDataPdf(allPredictions)}
        sx={{ mx: 1 }}
        endIcon={<DownloadIcon />}
      >
        Alle data (pdf)
      </Button>

      <TableContainer sx={{ mt: 2, backgroundColor: "aliceblue" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}></TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Gebruiker+Datum</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Wedstrijden</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>Top4</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allPredictions?.map((row) => (
              <TableRow
                key={row.userId}
                sx={{
                  "&:nth-of-type(even)": {
                    border: 0,
                    backgroundColor: "lightblue",
                  },
                }}
              >
                <TableCell>
                  {row.date && (
                    <>
                      <IconButton
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          color: "white",
                        }}
                        onClick={() => handleDownloadDataCSV(row)}
                      >
                        <DownloadIcon />
                      </IconButton>
                      <IconButton
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.secondary.main,
                          color: "white",
                        }}
                        onClick={() => handleDownloadDataPdf(row)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  <p style={{ fontWeight: "bold" }}>
                    {GetUserById(row.userId)?.name}
                  </p>
                  <p>
                    {row.date !== undefined
                      ? new Date(row.date).toLocaleString("en-GB")
                      : ""}
                  </p>
                </TableCell>
                <TableCell>
                  {row.date && (
                    <IconButton
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: "white",
                      }}
                      onClick={() => {
                        setSelectedResult(row);
                        setOpenResultsDialog(true);
                        setSelectedUser(GetUserById(row.userId));
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell>
                  {row.date && row.top4 !== undefined && (
                    <IconButton
                      sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: "white",
                      }}
                      onClick={() => {
                        setSelectedResult(row);
                        setOpenShowTop4(true);
                        setSelectedUser(GetUserById(row.userId));
                      }}
                    >
                      <OpenInNewIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );

  const adminToolbar = (
    <Toolbar>
      <Typography
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          mx: 2,
          fontWeight: "bolder",
        }}
      >
        {currentUser && GetUserById(currentUser.id)?.name}
        <Badge
          sx={{
            position: "absolute",
            transform: "translateY(-50%)",
            backgroundColor: "firebrick",
            color: "white",
            padding: "2px 4px",
            borderRadius: "4px",
            fontSize: "12px",
            fontWeight: "bold",
            zIndex: 1,
          }}
        >
          Admin
        </Badge>
      </Typography>
      <Button
        sx={{ color: "white", borderColor: "white", ml: "auto" }}
        onClick={() => {
          navigate("/");
        }}
        variant="outlined"
      >
        H⚽️me
      </Button>
    </Toolbar>
  );

  const showTop4Dialog = (
    <Dialog
      keepMounted
      onClose={() => setOpenShowTop4(false)}
      open={openShowTop4}
    >
      <DialogTitle color="grey" fontWeight="bold">
        Top 4 van {selectedUser?.name}
      </DialogTitle>
      <IconButton
        sx={{ position: "absolute", right: "8px", top: "8px" }}
        onClick={() => setOpenShowTop4(false)}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Positie</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Land</TableCell>
              </TableRow>
            </TableHead>
            {selectedResult?.top4 && (
              <TableBody>
                <TableRow key={1}>
                  <TableCell component="th" scope="row">
                    Eerste
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedResult?.top4.country1.name}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell component="th" scope="row">
                    Tweede
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedResult?.top4.country2.name}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell component="th" scope="row">
                    Derde
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedResult?.top4.country3.name}
                  </TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell component="th" scope="row">
                    Vierde
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedResult?.top4.country4.name}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );

  return (
    <>
      {showPredictionDialog}
      {showTop4Dialog}

      <AppBar position="static" sx={{ mb: 1 }}>
        {adminToolbar}
      </AppBar>
      <Alert sx={{ mt: 1, mb: 3 }} severity="info">
        😎 Admin omgeving voor stoere jongens
      </Alert>

      {isAdmin === false ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4" sx={{ my: 2, fontWeight: "bolder" }}>
            Jij mag hier helemaal niet komen! 😡
          </Typography>
          <Typography
            variant="h5"
            color="gray"
            sx={{ my: 2, fontWeight: "bolder" }}
          >
            Je bent geen admin
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/login")}
            sx={{ mt: 2 }}
          >
            Naar inloggen
          </Button>
        </Box>
      ) : allPredictions === undefined ? (
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h4">Data ophalen 🚛</Typography>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {downloadButtons}
          {showAllPredictionsByRound}
        </>
      )}
    </>
  );
}

export default Admin;
