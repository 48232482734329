import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { countriesArray } from "../countries";
import { Country, CountrySelect } from "../Types/Types";

interface EndScoreComponentProps {
  onTopFourChange: (countries: CountrySelect) => void;
}

const EndScoreComponent: React.FC<EndScoreComponentProps> = ({ onTopFourChange }) => {
  const [country1, setCountry1] = useState<Country | undefined>(undefined);
  const [country2, setCountry2] = useState<Country | undefined>(undefined);
  const [country3, setCountry3] = useState<Country | undefined>(undefined);
  const [country4, setCountry4] = useState<Country | undefined>(undefined);

  const handleCountry1Change = (event: any) => {
    const country = countriesArray.find((c) => c.name === event.target.value);
    setCountry1(country);
  };

  const handleCountry2Change = (event: any) => {
    const country = countriesArray.find((c) => c.name === event.target.value);
    setCountry2(country);
  };

  const handleCountry3Change = (event: any) => {
    const country = countriesArray.find((c) => c.name === event.target.value);
    setCountry3(country);
  };

  const handleCountry4Change = (event: any) => {
    const country = countriesArray.find((c) => c.name === event.target.value);
    setCountry4(country);
  };

  useEffect(() => {
    onTopFourChange({ country1, country2, country3, country4 });
  }
  , [country1, country2, country3, country4]);

  const countrySelector = (
    textLabel: string,
    inputLabel: string,
    onSelect: (event: any) => void,
    cantContain: (Country | undefined)[]
  ) => {
    return (
      <Grid container spacing={2} sx={{ my: 2, mx: 2 }}>
        <Grid item xs={4} sx={{ my: "auto" }}>
          <Typography variant="h6" fontWeight="bolder" sx={{color: "darkgrey"}}>{textLabel}</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <InputLabel>{inputLabel}</InputLabel>
            <Select onChange={onSelect}>
              {countriesArray
                .filter((c) => cantContain.includes(c) === false)
                .map((country) => {
                  return (
                    <MenuItem
                      key={country.code}
                      value={country.name}
                      sx={{ px: 2 }}
                    >
                      <img
                        src={`https://flagcdn.com/${country.code.toLowerCase()}.svg`}
                        style={{
                          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                          marginRight: 4,
                        }}
                        alt={country.name}
                        height={"12"}
                      />
                      {country.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Typography variant="h4" sx={{ my: 2, fontWeight: "bolder" }}>
        Top 4
      </Typography>
      <Typography variant="h6" sx={{ my: 2, color: "grey" }}>
        Kies de landen die volgens jou in de top 4 van het WK zullen eindigen
      </Typography>

      {countrySelector("🏆 Eerste:", "land 1", handleCountry1Change, [
        country2,
        country3,
        country4,
      ])}

      {countrySelector("Tweede:", "land 2", handleCountry2Change, [
        country1,
        country3,
        country4,
      ])}
      {countrySelector("Derde:", "land 3", handleCountry3Change, [
        country2,
        country1,
        country4,
      ])}
      {countrySelector("Vierde:", "land 4", handleCountry4Change, [
        country2,
        country3,
        country1,
      ])}
    </>
  );
};

export default EndScoreComponent;
