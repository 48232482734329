import { match } from "./Types/Types";
import { europeanCountries } from "./countries";

export const ronde1: match[] = [
  {
    teamA: europeanCountries.Germany,
    teamB: europeanCountries.Scotland,
  },
  {
    teamA: europeanCountries.Hungary,
    teamB: europeanCountries.Switzerland,
  },
  {
    teamA: europeanCountries.Spain,
    teamB: europeanCountries.Croatia,
  },
  {
    teamA: europeanCountries.Italy,
    teamB: europeanCountries.Albania,
  },
  {
    teamA: europeanCountries.Poland,
    teamB: europeanCountries.Netherlands,
  },
  {
    teamA: europeanCountries.Slovenia,
    teamB: europeanCountries.Denmark,
  },
  {
    teamA: europeanCountries.Serbia,
    teamB: europeanCountries.England,
  },
  {
    teamA: europeanCountries.Romania,
    teamB: europeanCountries.Ukraine,
  },
  {
    teamA: europeanCountries.Belgium,
    teamB: europeanCountries.Slovakia,
  },
  {
    teamA: europeanCountries.Austria,
    teamB: europeanCountries.France,
  },
  {
    teamA: europeanCountries.Turkey,
    teamB: europeanCountries.Georgia,
  },
  {
    teamA: europeanCountries.Portugal,
    teamB: europeanCountries.CzechRepublic,
  },
  {
    teamA: europeanCountries.Croatia,
    teamB: europeanCountries.Albania,
  },
  {
    teamA: europeanCountries.Germany,
    teamB: europeanCountries.Hungary,
  },
  {
    teamA: europeanCountries.Scotland,
    teamB: europeanCountries.Switzerland,
  },
  {
    teamA: europeanCountries.Slovenia,
    teamB: europeanCountries.Serbia,
  },
  {
    teamA: europeanCountries.Denmark,
    teamB: europeanCountries.England,
  },
  {
    teamA: europeanCountries.Spain,
    teamB: europeanCountries.Italy,
  },
  {
    teamA: europeanCountries.Slovakia,
    teamB: europeanCountries.Ukraine,
  },
  {
    teamA: europeanCountries.Poland,
    teamB: europeanCountries.Austria,
  },
  {
    teamA: europeanCountries.Netherlands,
    teamB: europeanCountries.France,
  },
  {
    teamA: europeanCountries.Georgia,
    teamB: europeanCountries.CzechRepublic,
  },
  {
    teamA: europeanCountries.Turkey,
    teamB: europeanCountries.Portugal,
  },
  {
    teamA: europeanCountries.Belgium,
    teamB: europeanCountries.Romania,
  },
  {
    teamA: europeanCountries.Switzerland,
    teamB: europeanCountries.Germany,
  },
  {
    teamA: europeanCountries.Scotland,
    teamB: europeanCountries.Hungary,
  },
  {
    teamA: europeanCountries.Croatia,
    teamB: europeanCountries.Italy,
  },
  {
    teamA: europeanCountries.Albania,
    teamB: europeanCountries.Spain,
  },
  {
    teamA: europeanCountries.Netherlands,
    teamB: europeanCountries.Austria,
  },
  {
    teamA: europeanCountries.France,
    teamB: europeanCountries.Poland,
  },
  {
    teamA: europeanCountries.England,
    teamB: europeanCountries.Slovenia,
  },
  {
    teamA: europeanCountries.Denmark,
    teamB: europeanCountries.Serbia,
  },
  {
    teamA: europeanCountries.Slovakia,
    teamB: europeanCountries.Romania,
  },
  {
    teamA: europeanCountries.Ukraine,
    teamB: europeanCountries.Belgium,
  },
  {
    teamA: europeanCountries.CzechRepublic,
    teamB: europeanCountries.Turkey,
  },
  {
    teamA: europeanCountries.Georgia,
    teamB: europeanCountries.Portugal,
  },
];

export const ronde2: match[] = [
  {
    teamA: europeanCountries.Switzerland,
    teamB: europeanCountries.Italy,
  },
  {
    teamA: europeanCountries.Germany,
    teamB: europeanCountries.Denmark,
  },
  {
    teamA: europeanCountries.England,
    teamB: europeanCountries.Slovakia,
  },
  {
    teamA: europeanCountries.Spain,
    teamB: europeanCountries.Georgia,
  },
  {
    teamA: europeanCountries.France,
    teamB: europeanCountries.Belgium,
  },
  {
    teamA: europeanCountries.Portugal,
    teamB: europeanCountries.Slovenia,
  },
  {
    teamA: europeanCountries.Romania,
    teamB: europeanCountries.Netherlands,
  },
  {
    teamA: europeanCountries.Austria,
    teamB: europeanCountries.Turkey,
  },
];

export const ronde3: match[] = [
  {
    teamA: europeanCountries.Spain,
    teamB: europeanCountries.Germany,
  },
  {
    teamA: europeanCountries.Portugal,
    teamB: europeanCountries.France,
  },
  {
    teamA: europeanCountries.England,
    teamB: europeanCountries.Switzerland,
  },
  {
    teamA: europeanCountries.Netherlands,
    teamB: europeanCountries.Turkey,
  },
];

export const ronde4: match[] = [
  {
    teamA: europeanCountries.Spain,
    teamB: europeanCountries.France,
  },
  {
    teamA: europeanCountries.Netherlands,
    teamB: europeanCountries.England,
  },
];

export const ronde5: match[] = [
  {
    teamA: europeanCountries.Spain,
    teamB: europeanCountries.England,
  },
];
