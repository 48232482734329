import React, { useState } from "react";
import MJAppBar from "./MJAppBar";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  GetCurrentUserFromLocalStorage,
  GetUserById,
} from "../Services/LoginService";
import DownloadIcon from "@mui/icons-material/Download";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import EmailIcon from "@mui/icons-material/Email";
import CloseIcon from "@mui/icons-material/Close";
import { PredictionJson } from "../Types/Types";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { handleDownloadDataPdf } from "./Admin/Admin.utils";

const Downloads: React.FC = () => {
  const [selectedResult, setSelectedResult] = useState<
    PredictionJson | undefined
  >(undefined);

  const [openResultsDialog, setOpenResultsDialog] = useState(false);
  const [openShowTop4, setOpenShowTop4] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(undefined);

  const [allPredictions, setAllPredictions] = useState<
    PredictionJson[] | undefined
  >(undefined);

  const currentUser = GetCurrentUserFromLocalStorage();
  const navigate = useNavigate();

  const handleDownload = (prediction: string) => {
    const formattedPrediction = JSON.parse(prediction);
    let formattedText = "";
    formattedPrediction.predictions.forEach((pred: any) => {
      formattedText += `Match: ${pred.match.teamA.name} VS ${pred.match.teamB.name}\n`;
      formattedText += `Scores: ${pred.teamAScore} - ${pred.teamBScore}\n\n`;
    });

    if (formattedPrediction.top4 !== undefined) {
      formattedText += `Top 4:\n`;
      formattedText += `1e: ${formattedPrediction.top4.country1.name}\n`;
      formattedText += `2e: ${formattedPrediction.top4.country2.name}\n`;
      formattedText += `3e: ${formattedPrediction.top4.country3.name}\n`;
      formattedText += `4e: ${formattedPrediction.top4.country4.name}\n`;
    }

    const element = document.createElement("a");
    const file = new Blob([formattedText], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `Ronde_${formattedPrediction.roundNumber}_${currentUser?.name}.txt`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleMailToSelf = (prediction: string) => {
    const formattedPrediction = JSON.parse(prediction);
    let formattedText = "";
    formattedPrediction.predictions.forEach((pred: any) => {
      formattedText += `${pred.match.teamA.name} VS ${pred.match.teamB.name}: ${pred.teamAScore} - ${pred.teamBScore}\n`;
    });
    if (formattedPrediction.top4 !== undefined) {
      formattedText += `Top 4:\n`;
      formattedText += `1e: ${formattedPrediction.top4.country1.name}\n`;
      formattedText += `2e: ${formattedPrediction.top4.country2.name}\n`;
      formattedText += `3e: ${formattedPrediction.top4.country3.name}\n`;
      formattedText += `4e: ${formattedPrediction.top4.country4.name}\n`;
    }
    const subject = `Voorspellingen van ronde ${formattedPrediction.roundNumber}`;
    const body = formattedText;
    const mailtoLink = `mailto:${"jouw@email-hier.nl"}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  };

  const fetchData = async () => {
    setTimeout(async () => {
      const response = await fetch(
        `https://magical-northcutt.81-173-112-48.plesk.page/api/getAllPredictions/${currentUser?.id}`
      );
      const data = await response.json();

      console.warn(data);
      setAllPredictions(data);
    }, 1000);
  };

  if (allPredictions === undefined) {
    setTimeout(() => {
      fetchData();
    }, 200);
  }

  const showTop4Dialog = (
    <Dialog
      keepMounted
      onClose={() => setOpenShowTop4(false)}
      open={openShowTop4}
    >
      <DialogTitle color="grey" fontWeight="bold">
        Top 4 van {selectedUser?.name}
      </DialogTitle>
      <IconButton
        sx={{ position: "absolute", right: "8px", top: "8px" }}
        onClick={() => setOpenShowTop4(false)}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Positie</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Land</TableCell>
              </TableRow>
            </TableHead>
            {selectedResult && selectedResult.top4 && (
              <TableBody>
                <TableRow key={1}>
                  <TableCell component="th" scope="row">
                    Eerste
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedResult?.top4.country1.name}
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell component="th" scope="row">
                    Tweede
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedResult?.top4.country2.name}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell component="th" scope="row">
                    Derde
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedResult?.top4.country3.name}
                  </TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell component="th" scope="row">
                    Vierde
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {selectedResult?.top4.country4.name}
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );

  const showPredictionDialog = (
    <Dialog
      keepMounted
      onClose={() => setOpenResultsDialog(false)}
      open={openResultsDialog}
    >
      <DialogTitle color="grey" fontWeight="bold">
        Voorspellingen van {selectedUser?.name}
        <IconButton
          sx={{ position: "absolute", right: "8px", top: "8px" }}
          onClick={() => setOpenResultsDialog(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Match</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Scores</TableCell>
              </TableRow>
            </TableHead>
            {selectedResult !== undefined ? (
              <TableBody>
                {selectedResult?.predictions.map((pred) => (
                  <TableRow
                    key={pred.match.teamA.code + pred.match.teamB.code}
                    sx={{
                      "&:nth-of-type(even)": {
                        border: 0,
                        backgroundColor: "lightblue",
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {pred.match.teamA.name} VS {pred.match.teamB.name}
                    </TableCell>
                    <TableCell>
                      {pred.teamAScore} - {pred.teamBScore}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ) : (
              <CircularProgress />
            )}
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );

  return (
    <>
      <MJAppBar />
      {showPredictionDialog}
      {showTop4Dialog}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Box sx={{ mx: 3 }}>
          <h2>Hoi {currentUser?.name} !</h2>
          <h4 style={{ color: "gray" }}>
            Hier kun je al je voorspellingen bekijken en downloaden.
          </h4>

          <Button
            sx={{ my: 2 }}
            size="large"
            onClick={() => navigate("/success")}
            variant="contained"
            color="primary"
            startIcon={<ArrowBackIcon />}
          >
            Terug
          </Button>

          <TableContainer sx={{ mt: 2, backgroundColor: "aliceblue" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Ronde</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Wedstrijden</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Top4</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allPredictions?.map((row) => (
                  <TableRow
                    key={row.userId}
                    sx={{
                      "&:nth-of-type(even)": {
                        border: 0,
                        backgroundColor: "lightblue",
                      },
                    }}
                  >
                    <TableCell>
                      <Button
                        onClick={() => handleDownload(JSON.stringify(row))}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          color: "white",
                          m: 1,
                        }}
                        endIcon={<DownloadIcon />}
                      >
                        file
                      </Button>
                      <Button
                        onClick={() => handleDownloadDataPdf(row)}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          color: "white",
                          m: 1,
                        }}
                        endIcon={<DownloadIcon />}
                      >
                        pdf
                      </Button>
                      <Button
                        onClick={() => handleMailToSelf(JSON.stringify(row))}
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          color: "white",
                          m: 1,
                        }}
                        endIcon={<EmailIcon />}
                      >
                        Mail
                      </Button>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="h6"
                        sx={{ color: "grey" }}
                        style={{ fontWeight: "bold" }}
                      >
                        {row.roundNumber}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.primary.main,
                          color: "white",
                        }}
                        onClick={() => {
                          setSelectedResult(row);
                          setOpenResultsDialog(true);
                          setSelectedUser(GetUserById(row.userId));
                        }}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      {row.top4 !== undefined && (
                        <IconButton
                          sx={{
                            backgroundColor: (theme) =>
                              theme.palette.primary.main,
                            color: "white",
                          }}
                          onClick={() => {
                            setSelectedResult(row);
                            setOpenShowTop4(true);
                            setSelectedUser(GetUserById(row.userId));
                          }}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {allPredictions === undefined && <CircularProgress />}
        </Box>
      </Box>
    </>
  );
};

export default Downloads;
