import { Country } from "./Types/Types";

export const europeanCountries = {
    Albania: { name: 'Albanië', code: 'AL', ranking: 64},
    Austria: { name: 'Oostenrijk', code: 'AT', ranking: 25},
    Belgium: { name: 'België', code: 'BE', ranking: 4},
    Croatia: { name: 'Kroatië', code: 'HR', ranking: 10},
    CzechRepublic: { name: 'Tsjechië', code: 'CZ', ranking: 40},
    Denmark: { name: 'Denemarken', code: 'DK', ranking: 21},
    England: { name: 'Engeland', code: 'gb-eng', ranking: 3},
    France: { name: 'Frankrijk', code: 'FR', ranking: 2},
    Georgia: { name: 'Georgië', code: 'GE', ranking: 77},
    Germany: { name: 'Duitsland', code: 'DE', ranking: 16},
    Hungary: { name: 'Hongarije', code: 'HU', ranking: 27},
    Italy: { name: 'Italië', code: 'IT', ranking: 9},
    Netherlands: { name: 'Nederland', code: 'NL', ranking: 6},
    Poland: { name: 'Polen', code: 'PL', ranking: 30},
    Portugal: { name: 'Portugal', code: 'PT', ranking: 7},
    Romania: { name: 'Roemenië', code: 'RO', ranking: 45},
    Scotland: { name: 'Schotland', code: 'GB-SCT', ranking: 34},
    Serbia: { name: 'Servië', code: 'RS', ranking: 32},
    Slovakia: { name: 'Slowakije', code: 'SK', ranking: 48},
    Slovenia: { name: 'Slovenië', code: 'SI', ranking: 55},
    Spain: { name: 'Spanje', code: 'es', ranking: 8},
    Switzerland: { name: 'Zwitserland', code: 'CH', ranking: 19},
    Turkey: { name: 'Turkije', code: 'TR', ranking: 35},
    Ukraine: { name: 'Oekraïne', code: 'UA', ranking: 24},
};

export const countriesArray = Object.values(europeanCountries).map((country: Country) => country).sort((a, b) => a.name.localeCompare(b.name));