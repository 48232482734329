import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { match } from "../Types/Types";

interface props {
  match: match;
  open: boolean;
  onDrawerClose: () => void;
}

const PeakDrawer: React.FC<props> = (props) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  return (
    <>
      <Drawer
        open={open}
        anchor="bottom"
        style={{ textAlign: "center" }}
        onClose={props.onDrawerClose}
      >
        <IconButton
          aria-label="delete"
          size="large"
          sx={{ ml: "auto", mr: 1, mt: 1 }}
          onClick={() => {setOpen(false); props.onDrawerClose();}}
        >
          <CloseIcon fontSize="large" />
        </IconButton>
        <Typography variant="h5" sx={{ mt: 1, fontWeight: "bold" }}>
          Even spieken 👀
        </Typography>
        <Typography
          variant="h6"
          sx={{ mx: 2, mt: 1, color: "grey", fontWeight: "bold" }}
        >
          Posities van deze twee landen in de FIFA wereld ranglijst
        </Typography>

        <Grid container sx={{ mt: 6 }}>
          <Grid item xs={5} mx="auto">
            <Box>
              <img
                src={`https://flagcdn.com/${props.match.teamA.code.toLowerCase()}.svg`}
                style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" }}
                alt={props.match.teamA.name}
                height={"60"}
              />
              <Typography variant="h6">{props.match.teamA.name}</Typography>

              <Typography variant="h3">
                <Box
                  sx={{ p: 2, mx: "auto", my: 2 }}
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: (props.match.teamA.ranking < props.match.teamB.ranking ? "seagreen" : "firebrick"),
                    borderRadius: "50px",
                    color: "white",
                  }}
                >
                  {props.match.teamA.ranking}
                </Box>
              </Typography>
            </Box>
          </Grid>
          <Divider orientation="vertical" variant="middle" flexItem />
          <Grid item xs={5} mx="auto">
            <Box>
              <img
                src={`https://flagcdn.com/${props.match.teamB.code.toLowerCase()}.svg`}
                height={"60"}
                style={{ boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)" }}
                alt={props.match.teamB.name}
              />
              <Typography variant="h6">{props.match.teamB.name}</Typography>

              <Typography variant="h3">
                <Box
                  sx={{ p: 2, mx: "auto", my: 2 }}
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: (props.match.teamB.ranking < props.match.teamA.ranking ? "seagreen" : "firebrick"),
                    borderRadius: "50px",
                    color: "white",
                  }}
                >
                        {props.match.teamB.ranking}
                </Box>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default PeakDrawer;
