import { useNavigate } from "react-router-dom";
import { User } from "../Types/Types";
import usersDb from "../Users.json";

export const CheckIfUserExists = async (id: number): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    setTimeout(() => {
      getUserFromJson(id)
        .then((user: User | undefined) => {
          if (user === undefined) {
            resolve(false);
          } else {
            resolve(true);
          }
        })
        .catch(() => {
          reject("User not found CheckIfUserExists2");
        });
    }, 2000);
  });
};

export const getUserFromJson = (id: number): Promise<User | undefined> => {
  return new Promise<User | undefined>((resolve, reject) => {
    const users: User[] = usersDb.users as User[];

    const user = users.find((user: User) => user.id === id);
    if (user) {
      console.log(user);
      resolve(user);
      SetCurrentUserInLocalStorage(user);
    } else {
      resolve(undefined);
    }
  });
};

export const getAllUsers = () => {
  return new Promise<User[] | undefined>((resolve, reject) => {
    const users: User[] = usersDb.users as User[];
    if (users) {
      resolve(users);
    } else {
      resolve(undefined);
    }
  });
};

export const SetCurrentUserInLocalStorage = (user: User) => {
  localStorage.setItem("currentUser", JSON.stringify(user));
};

export const GetCurrentUserFromLocalStorage = (): User | undefined => {
  const user = localStorage.getItem("currentUser");
  if (user) {
    return JSON.parse(user) as User;
  }
  return undefined;
};

export const GetUserById = (id: number): User | undefined => {
  const users: User[] = usersDb.users as User[];
  return (
    users.find((user) => user.id === id) || {
      name: "geen gebruikersnaam",
      id: 0,
    }
  );
};

export const logout = () => {
  deleteCurrentUserFromLocalStorage();
};

export const deleteCurrentUserFromLocalStorage = () => {
  localStorage.removeItem("currentUser");
};

export const checkIfAllIdsUnique = () => {
  const users: User[] = usersDb.users as User[];
  const ids = users.map((user) => user.id);
  const duplicateIds = ids.filter((id, index) => ids.indexOf(id) !== index);
  console.log(duplicateIds);
};
