
import { Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import './Components.css'
import { useEffect, useState } from 'react';
import { CheckIfUserExists } from '../Services/LoginService';

function LoginComponent() {

  const [userVerfied, setUserVerfied] = useState<boolean | undefined>(undefined);

  let { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  //Added because useffect kept triggering
  let userChecked = false;

  useEffect(() => {
    if (id !== undefined && !isNaN(Number(id))) {
      CheckIfUserExists(Number(id)).then((result) => {
        if (result === true && userChecked === false) {
          userChecked = true;
          setUserVerfied(true);
          setTimeout(() => {
            navigate('/');
          }, 3000);
        } else if (!result && !userChecked) {
          userChecked = true;
          setUserVerfied(false);
        }
      });
    }
    else if (id === undefined) {

    }

  }, [id]);

  return (
    <>
      {userVerfied === undefined ? (
        <Box display='flex' justifyContent='center' alignItems='center' height='100vh' textAlign='center'>
          <Box>
            <h1>Je wordt ingelogd</h1>
            <h2>Je id is: {id}</h2>
            <Box style={{ animation: 'spin 1s linear infinite', fontSize: '40px', width:"auto" }}>
              <h1>⚽</h1>
            </Box>
          </Box>
        </Box>
      ) : (
        userVerfied === true ? (
          <Box display='flex' justifyContent='center' alignItems='center' height='100vh' textAlign='center'>
            <Box>
              <h1>Je bent ingelogd!</h1>
              <h3>Je wordt omgeleid...</h3>
              <Box>
                <h1>✅</h1>
              </Box>
            </Box>
          </Box>
        ) : (<Box display='flex' justifyContent='center' alignItems='center' height='100vh' textAlign='center'>
          <Box>
            <h1>Gebruiker niet gevonden</h1>
            <h1>❌</h1>
            <h3 style={{ color: 'gray' }}>Check of je de juiste link hebt gebruikt of neem contact op met mj-toto</h3>
            <Button onClick={() => navigate('/login')} style={{ width: '60%' }} variant="contained" color="primary">
            terug
          </Button>
          </Box>
        </Box>
        ))
      }
    </>
  );
}

export default LoginComponent;